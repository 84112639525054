export const PostersData = [
    {
        image: "posters/poster1.jpg",
    },
    {
        image: "posters/poster2.jpg",
        
    },
    {
        image: "posters/poster3.jpg",
    }
    ,
    {
        image: "posters/poster4.jpg",
    }
    ,
    {
        image: "posters/poster5.jpg",
    }
    ,
    {
        image: "posters/poster6.jpg",
    }
    ,
    {
        image: "posters/poster7.jpg",
    }
];

